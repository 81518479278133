module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-transition-link/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Vu Luong","short_name":"Vu","start_url":"/","background_color":"#1A1A1A","theme_color":"#1A1A1A","display":"minimal-ui","icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"83490b9b481c7379fd2410be40b3066d"},
    },{
      plugin: require('../node_modules/gatsby-plugin-mixpanel/gatsby-browser.js'),
      options: {"plugins":[],"apiToken":"01ba9aaafc811c22cac104df052ca61f","enableOnDevMode":false,"pageViews":{"/":"------ Home","/blinding":"------ Blinding","/equal-outcome":"------ Equality of Outcome","/equal-opportunity":"------ Equality of Opportunity"}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
