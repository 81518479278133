// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blinding-js": () => import("./../../../src/pages/blinding.js" /* webpackChunkName: "component---src-pages-blinding-js" */),
  "component---src-pages-equal-opportunity-js": () => import("./../../../src/pages/equal-opportunity.js" /* webpackChunkName: "component---src-pages-equal-opportunity-js" */),
  "component---src-pages-equal-outcome-js": () => import("./../../../src/pages/equal-outcome.js" /* webpackChunkName: "component---src-pages-equal-outcome-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

